import React from "react";
import CountUp from "react-countup";

const StatCard = ({ iconClass, number, label, suffix }) => (
  <div className="bg-white rounded-2xl p-8 text-center relative overflow-hidden shadow-lg transform hover:scale-105 transition-transform duration-300">
    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-16 h-2 bg-purple-600 rounded-b-full"></div>
    <i
      className={`counter-icon ${iconClass} text-5xl text-[#05C1AF] mb-4 block`}
    ></i>
    <CountUp
      end={number}
      duration={2.5}
      suffix={suffix}
      className="text-5xl font-bold mb-2 text-purple-700"
    />
    <div className="w-16 h-0.5 bg-purple-600 mx-auto mb-2"></div>
    <div className="uppercase text-sm font-semibold tracking-wider text-purple-800">
      {label}
    </div>
  </div>
);

const StatsSection = () => {
  return (
    <section className="py-20 bg-gradient-to-br from-purple-700 to-purple-900">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <StatCard
            iconClass="flaticon-dog-in-front-of-a-man"
            number={5}
            label="B2B clients"
          />
          <StatCard
            iconClass="flaticon-dog-2"
            number={100}
            label="Treated Pets"
            suffix="+"
          />
          <StatCard
            iconClass="flaticon-dog-with-first-aid-kit-bag"
            number={97}
            label="Accuracy"
            suffix="%"
          />
        </div>
      </div>
    </section>
  );
};

export default StatsSection;
