import React from "react";
import { motion } from "framer-motion";
import {
  FaMicroscope,
  FaBolt,
  FaPiggyBank,
  FaMedal,
  FaMobileAlt,
  FaPaw,
} from "react-icons/fa";

const FeatureCard = ({ icon: Icon, title, description, index }) => (
  <motion.div
    className="bg-white rounded-xl shadow-lg p-6 flex flex-col items-center text-center transition-all duration-300 hover:shadow-xl"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: index * 0.1 }}
  >
    <div className="bg-purple-100 p-3 rounded-full mb-4">
      <Icon className="text-3xl text-[#05C1AF]" />
    </div>
    <h3 className="text-xl font-semibold mb-2 text-purple-700">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

const FeaturesSection = () => {
  const features = [
    {
      icon: FaMicroscope,
      title: "AI-Powered Accuracy",
      description:
        "Our cutting-edge AI provides vet-level skin assessments with 97% accuracy.",
    },
    {
      icon: FaBolt,
      title: "Lightning-Fast Results",
      description:
        "Get detailed health insights for your pet in just 30 seconds.",
    },
    {
      icon: FaPiggyBank,
      title: "Save Time & Money",
      description:
        "Avoid unnecessary vet visits and get peace of mind instantly.",
    },
    {
      icon: FaMedal,
      title: "Trusted by Experts",
      description:
        "Developed with leading veterinarians for reliable, professional-grade care.",
    },
    {
      icon: FaMobileAlt,
      title: "24/7 Availability",
      description: "Access expert pet health advice anytime, anywhere.",
    },
    {
      icon: FaPaw,
      title: "Tailored for Your Pet",
      description:
        "Personalized recommendations based on your pet's unique needs.",
    },
  ];

  return (
    <section className="py-16 bg-gradient-to-br from-purple-50 to-indigo-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-purple-700 mb-2">
          Why Pet Owners Love PawSmart
        </h2>
        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
          Discover the features that make PawSmart the go-to solution for pet
          health
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
