import React from "react";
import { FaLinkedin, FaTiktok, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer id="contact" className="bg-gray-900 text-gray-300 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-start">
          {/* Logo and Tagline */}
          <div className="w-full md:w-auto mb-6 md:mb-0">
            <h2 className="text-white font-bold text-2xl mb-1">PawSmart</h2>
            <p className="text-gray-400 text-sm">
              Revolutionizing Veterinary care with AI
            </p>
          </div>

          {/* Quick Links */}
          <div className="w-full md:w-auto mb-6 md:mb-0">
            <h4 className="font-semibold mb-3 text-white text-lg">
              Quick Links
            </h4>
            <ul className="space-y-2">
              {["Home", "About Us", "FAQ", "Contact"].map((link) => (
                <li key={link}>
                  <a
                    href="#"
                    className="hover:text-white transition duration-300"
                  >
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Information */}
          <div className="w-full md:w-auto mb-6 md:mb-0">
            <h4 className="font-semibold mb-3 text-white text-lg">
              Contact Us
            </h4>
            <p className="mb-2">
              Email:{" "}
              <a
                href="mailto:Woofandmeows@pawsmart.ai"
                className="hover:text-white transition duration-300"
              >
                Woofandmeows@pawsmart.ai
              </a>
            </p>
            <p>
              Phone:{" "}
              <a
                href="tel:+16476844584"
                className="hover:text-white transition duration-300"
              >
                +1 647-684-4584
              </a>
            </p>
          </div>

          {/* Social Media */}
          <div className="w-full md:w-auto">
            <h4 className="font-semibold mb-3 text-white text-lg">Follow Us</h4>
            <div className="flex space-x-4">
              {[
                {
                  icon: FaLinkedin,
                  url: "https://www.linkedin.com/company/pawsmart-ai",
                },
                {
                  icon: FaTiktok,
                  url: "https://www.tiktok.com/@stubby.ai?_t=8nHVQp65dlm&_r=1",
                },
                {
                  icon: FaInstagram,
                  url: "https://www.instagram.com/pawsmart.ai?igsh=MXV2YzJoa214em9sMw%3D%3D&utm_source=qr",
                },
              ].map((social, index) => (
                <a
                  key={index}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white transition duration-300"
                >
                  <social.icon size={24} />
                </a>
              ))}
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className="border-t border-gray-700 my-6"></div>

        {/* Copyright */}
        <div className="text-center text-gray-400 text-sm">
          <p>
            &copy; {new Date().getFullYear()} PawSmart.AI. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
