import React from "react";
import { motion } from "framer-motion";

const TestimonialCard = ({ quote, author, role, delay }) => (
  <motion.div
    className="bg-white rounded-lg shadow-lg p-6 flex flex-col justify-between border-l-4 border-purple-500"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay }}
  >
    <p className="text-gray-700 italic mb-4 text-lg">"{quote}"</p>
    <div>
      <p className="font-semibold text-purple-600">{author}</p>
      <p className="text-sm text-gray-500">{role}</p>
    </div>
  </motion.div>
);

const TestimonialsSection = () => {
  const testimonials = [
    {
      quote:
        "PawSmart saved me a midnight trip to the vet. The AI quickly identified my dog's rash and suggested home remedies that worked!",
      author: "Sarah J.",
      role: "Dog Owner",
    },
    {
      quote:
        "As a first-time pet owner, PawSmart has been my go-to resource. It's like having a vet on call 24/7!",
      author: "Mike T.",
      role: "Cat Enthusiast",
    },
    {
      quote:
        "The accuracy of PawSmart's skin condition diagnosis is impressive. It's a game-changer for pet healthcare!",
      author: "Dr. Emily R.",
      role: "Veterinarian",
    },
    {
      quote:
        "I love how PawSmart gives me peace of mind. It's helped me understand my pet's health better than ever before.",
      author: "Alex M.",
      role: "Pet Parent",
    },
  ];

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-purple-700 mb-2">
          What Our Users Say
        </h2>
        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
          Real stories from pet owners who've experienced the power of PawSmart
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} delay={index * 0.2} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
