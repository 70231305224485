import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Footer";
import StatsSection from "./StatsSection";
import TestimonialsSection from "./TestimonialsSection";
import FeaturesSection from "./FeaturesSection";
import HeroSection from "./HeroSection";
import CTASection from "./CTASection";
import AppPromotionSection from "./AppPromotionSection";
import NavBar from "./NavBar";
import PartnerSlider from './PartnerSlider';

const LandingPage = () => {
  const scrollToContact = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const redirectToLogin = () => {
    window.location.href = "https://app.pawsmart.ai/login";
  };

  return (
    <div className="font-sans text-gray-800">
      <NavBar
        scrollToContact={scrollToContact}
        redirectToLogin={redirectToLogin}
      />
      <HeroSection />
      <PartnerSlider />
      <FeaturesSection />
      <TestimonialsSection />
      <StatsSection />
      <CTASection redirectToLogin={redirectToLogin} />
      <Footer />
    </div>
  );
};

export default LandingPage;
