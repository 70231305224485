import React from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const partners = [
  { img: require("./Asserts/partner1.png") },
  { img: require("./Asserts/partner2.png") },
  { img: require("./Asserts/partner3.png") },
  { img: require("./Asserts/partner4.png") },
  { img: require("./Asserts/partner5.png") },
  { img: require("./Asserts/partner6.png") },
  { img: require("./Asserts/partner7.png") },
  { img: require("./Asserts/partner8.png") },
  // Add more partners as needed
];

const PartnerSlider = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <section className="py-16 bg-gradient-to-br from-purple-50 to-indigo-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-purple-700 mb-16">
          Powered By Global Partners
        </h2>
        <div className="mt-12">
          <Slider {...settings}>
            {partners.map((partner, index) => (
              <motion.div
                key={index}
                className="partner-slide px-4"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <img
                  src={partner.img}
                  alt={`Partner ${index + 1}`}
                  className="mx-auto h-16 object-contain"
                />
              </motion.div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default PartnerSlider;
