import React from "react";
import { motion } from "framer-motion";
import { PawPrint } from "lucide-react";

const CTASection = ({ redirectToLogin }) => {
  return (
    <section className="py-16 md:py-24 bg-purple-100">
      <div className="container mx-auto px-4 max-w-4xl">
        <div className="text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-purple-700">
            Ready to Revolutionize Your Pet Care?
          </h2>
          <p className="text-lg md:text-xl mb-10 text-gray-700">
            Join thousands of pet owners who've discovered the power of
            AI-assisted pet health.
          </p>
          <motion.button
            onClick={redirectToLogin}
            className="inline-flex items-center justify-center bg-purple-500 text-white px-8 py-4 rounded-full font-bold text-lg transition duration-300 shadow-lg hover:bg-purple-600"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <PawPrint className="mr-2" size={24} color="#05C1AF" />
            Start Your Free Scan Now
          </motion.button>
        </div>
      </div>
    </section>
  );
};

export default CTASection;
