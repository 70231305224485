import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { MessageCircle, Calendar } from "lucide-react";
import HomeSVg from "./Asserts/Veterinary-amico.svg";

// Improved custom hook for typing effect
const useTypingEffect = (text, typingSpeed = 150) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < text.length) {
        setDisplayedText(text.slice(0, i + 1));
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, typingSpeed);

    return () => clearInterval(typingInterval);
  }, [text, typingSpeed]);

  return displayedText;
};

const HeroSection = () => {
  const redirectToAIPetGuru = () => {
    window.location.href = "https://chat.pawsmart.ai";
  };

  const redirectToVetBooking = () => {
    window.location.href = "https://booking.pawsmart.ai";
  };

  const titleText = "Pet Health at Your Fingertips";
  const displayedText = useTypingEffect(titleText, 100);

  const purpleText = "Pet Health";
  const purpleTextLength = purpleText.length;

  return (
    <section className="py-16 md:py-24 bg-gradient-to-br from-purple-100 via-blue-50 to-indigo-100">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center">
          <motion.div
            className="md:w-1/2 mb-12 md:mb-0"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-4xl sm:text-5xl font-bold mb-6 leading-tight">
              <span className="text-purple-600">
                {displayedText.slice(0, purpleTextLength)}
              </span>
              <span className="text-gray-800">
                {displayedText.slice(purpleTextLength)}
              </span>
              <span className="text-gray-800 invisible">
                {titleText.slice(displayedText.length)}
              </span>
            </h1>
            <p className="text-lg text-gray-700 mb-8">
              PawSmart brings the future of pet care to you. Get instant
              AI-powered skin assessments and expert advice for your beloved
              pets in just seconds!
            </p>
            <div className="flex flex-col space-y-4 items-start">
              <motion.button
                onClick={redirectToAIPetGuru}
                className="w-full sm:w-auto bg-blue-600 text-white px-8 py-3 rounded-full font-semibold text-lg transition duration-300 shadow-md hover:bg-blue-700 flex items-center justify-center"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <MessageCircle className="mr-2" size={24} color="#05C1AF" />
                Chat with Dr Stubby AI
              </motion.button>
              <p className="text-gray-600 text-sm">
                We know you would still need a veterinarian's opinion. PawSmart
                puts a 24/7 team of veterinary experts right at your fingertips!
              </p>
              <motion.button
                // onClick={redirectToVetBooking}
                className="w-full sm:w-auto bg-purple-600 text-white px-8 py-3 rounded-full font-semibold text-lg transition duration-300 shadow-md hover:bg-purple-700 flex items-center justify-center"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Calendar className="mr-2" size={24} color="#05C1AF" />
                Book a consultation
              </motion.button>
            </div>
          </motion.div>
          <motion.div
            className="md:w-1/2 md:pl-12"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <img
              src={HomeSVg}
              alt="PawSmart AI Pet Care"
              className="w-full h-auto"
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
