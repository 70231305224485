import React, { useState, useEffect } from "react";
import { Menu, X, Scan } from "lucide-react";

const NavBar = ({ scrollToContact, redirectToLogin }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleScrollToContact = (e) => {
    scrollToContact(e);
    setIsMobileMenuOpen(false);
  };

  return (
    <nav
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? "bg-white shadow-md" : "bg-transparent"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          <div className="flex-shrink-0">
            <a href="#" className="flex items-center">
              <img
                src="/nav_logo.ico"
                alt="PawSmart Logo"
                className="h-12 w-auto"
              />
            </a>
          </div>
          <div className="hidden md:flex md:items-center md:space-x-8">
            <a
              href="#"
              className={`${
                isScrolled
                  ? "text-gray-600 hover:text-purple-600"
                  : "text-purple-600 hover:text-purple-800"
              } px-3 py-2 rounded-md text-sm font-medium transition-colors duration-300`}
            >
              Home
            </a>
            <a
              href="#contact"
              onClick={handleScrollToContact}
              className={`${
                isScrolled
                  ? "text-gray-600 hover:text-purple-600"
                  : "text-purple-600 hover:text-purple-800"
              } px-3 py-2 rounded-md text-sm font-medium transition-colors duration-300`}
            >
              Contact
            </a>
            <button
              onClick={redirectToLogin}
              className={`
                flex items-center px-6 py-2 rounded-full text-sm font-medium 
                transition-all duration-300 transform hover:scale-105
                bg-purple-500 text-white hover:bg-purple-600 shadow-lg hover:shadow-xl
              `}
            >
              <Scan className="mr-2 h-4 w-4 text-[#05C1AF]" />
              Try Free Scan
            </button>
          </div>
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMobileMenu}
              className={`inline-flex items-center justify-center p-2 rounded-md ${
                isScrolled
                  ? "text-gray-400 hover:text-gray-500 hover:bg-gray-100"
                  : "text-[#05C1AF] hover:text-[#05C1AF]"
              } focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500`}
            >
              <span className="sr-only">Open main menu</span>
              {isMobileMenuOpen ? (
                <X className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={`${
          isMobileMenuOpen ? "block" : "hidden"
        } md:hidden bg-white shadow-lg`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <a
            href="#"
            className="text-gray-600 hover:text-purple-600 block px-3 py-2 rounded-md text-base font-medium"
          >
            Home
          </a>
          <a
            href="#contact"
            onClick={handleScrollToContact}
            className="text-gray-600 hover:text-purple-600 block px-3 py-2 rounded-md text-base font-medium"
          >
            Contact
          </a>
          <button
            onClick={redirectToLogin}
            className="w-full text-left bg-purple-600 text-white px-3 py-2 rounded-md text-base font-medium hover:bg-purple-700 transition duration-300 flex items-center"
          >
            <Scan className="mr-2 h-4 w-4 text-[#05C1AF]" />
            Try Free Scan
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
